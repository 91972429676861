<template>
  <div class="contextual-help t3">
    <div class="contextual-help__tip">
      <Tip back="/" text="Entdecke H&M HOME mit deiner Kamera" />
      <div class="contextual-help__tip-link" :onclick="close">
        <CrossIcon />
      </div>
    </div>
    <div class="contextual-help__screen camera-on">
      <div class="contextual-help__image">
        <img src="/images/facade2.jpg" srcset="/images/facade2@2x.jpg 2x" alt="">
      </div>
      <div class="contextual-help__controls contextual-help__controls_blocked">
        <CameraControls legend />
      </div>
    </div>
  </div>
</template>

<script>
import Tip from '@/components/Tip.vue'
import CameraControls from '@/components/CameraControls.vue'
import CrossIcon from '@/assets/images/cross.svg?inline'

export default {
  name: 'Tutorial3',
  components: {
    Tip,
    CameraControls,
    CrossIcon
  },
  methods: {
    close: function() {
      router.go(-3);
    }
  },
  mounted: function() {
    store.set('currentPage', 'Tutorial3');
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";

.t3 {
  background: $background-color;
}
.contextual-help {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contextual-help__screen {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/frame.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all 0.6s ease;
  }
}

.contextual-help__image {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease 0.2s;
  transform-origin: center;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.camera-on {
  &.contextual-help__screen {
    &::after {
      background-size: auto 200%;
      opacity: 0;
      pointer-events: none;
    }
  }
}

.contextual-help__tip-link {
  position: absolute;
  top: 18px;
  right: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-decoration: none;
  color: inherit;
  z-index: 1;

  svg {
    display: block;
    width: 12px;
    height: 12px;
  }
}

.contextual-help__controls_blocked {
  pointer-events: none;

  * {
    pointer-events: none;
  }

  .camera-controls__label.hidden {
    opacity: 1 !important;
    display: block !important;
  }
}
</style>
